import { lazy } from 'react';
import { Route, Routes } from 'react-router';

const AppLayout = lazy(() => import('shared/components/app-layout'));
const CredentialPage = lazy(() => import('pages/credential'));

export default function App () {
  return (
    <Routes>
      <Route element={<AppLayout />}
        path='*'>
        <Route element={<CredentialPage />}
          index />
      </Route>
    </Routes>
  );
}
