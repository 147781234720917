import { QueryClientProvider } from '@tanstack/react-query';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import { Toaster } from 'sonner';

import { TooltipProvider } from 'shared/components/ui/tooltip';

import { queryClient } from './query-client';

export default function AppProviders ({ children }: Props) {
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster position='top-right'
        richColors />
      <BrowserRouter>
        <Suspense fallback={null}>
          <TooltipProvider delayDuration={250}>
            <Suspense fallback={null}>{children}</Suspense>
          </TooltipProvider>
        </Suspense>
      </BrowserRouter>
    </QueryClientProvider>
  );
}

interface Props {
  children: React.ReactNode;
}
